import React, { createContext } from 'react'
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom'

const gaContext = createContext()


function GAProvider(props) {

  const location = useLocation()

  React.useEffect(() => {
    ReactGA.initialize('UA-164499510-1', {
      debug: true,
      titleCase: false,
    });
  }, [])

  return <gaContext.Provider>
    {props.children}
  </gaContext.Provider>

}

export function useGA() {

  const location = useLocation()

  const pageView = React.useCallback(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [])

  return [pageView]

}

export default GAProvider