import React from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation, useLazyQuery, useApolloClient } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'

import { useOrganizations } from 'modules/organization-provider'
import { useCapabilities } from 'utility/capabilities-provider'

const scaffoldingContext = React.createContext()

const GET_SCAFFOLDING_CONTENTS = gql`
  query certificates($path: String, $organizationID: String) {
    certificates(
      path: $path, 
      organizationID: $organizationID
    ) {
      id
    }
  }
`

const GET_SCAFFOLDING = gql`
  query getScaffolding($organizationID: String!) {
    getOrganizations(ids: [$organizationID]) {
      id
      scaffolding
    }
  }
`

const MUTATE_SCAFFOLDING = gql`
  mutation mutateScaffolding($organizationID: String!, $action: ScaffoldingMutationAction) {
    mutateScaffolding(organizationID: $organizationID, action: $action)
  }
`

function getBreadcrumbs(path, scaffolding) {

  if (typeof path === "undefined") return []

  let last = path.split('/').slice(-1)[0]

  if (last === "") last = "/"

  const currentDirectory = scaffolding.find(item => item.path === last)

  if (typeof currentDirectory === "undefined") throw new Error("Directory not found")

  let done = false
  let breadcrumbs = [currentDirectory]

  while (!done) {
    const latest = breadcrumbs[breadcrumbs.length - 1]

    if (typeof latest.parent === "undefined") {
      done = true
    } else {
      const parent = scaffolding.find(item => item.id === latest.parent)
      breadcrumbs.push(parent)
    }

  }

  return breadcrumbs.reverse()

}

export function useScaffolding() {

  const value = React.useContext(scaffoldingContext)

  return value

}

function ScaffoldingProvider(props) {

  const { selectedOrganization } = useOrganizations()
  const { "*": path } = useParams()
  const { data, loading, called, refetch } = useQuery(GET_SCAFFOLDING, {
    variables: {
      organizationID: selectedOrganization.id
    }
  })

  const bonPath = `/${path.replace(/\/+$/, '')}`

  const capabilities = useCapabilities()
  const checkCapabilities = React.useCallback(() => {

    const { isAdmin, isOwner } = capabilities

    return new Promise((resolve, reject) => {

      if (isAdmin || isOwner) {
        resolve()
      } else {
        const err = new Error("Solo gli amministratori possono modificare le cartelle.")
        reject(err)
      }

    })

  }, [capabilities])

  const [mutateScaffolding] = useMutation(MUTATE_SCAFFOLDING)

  const scaffolding = JSON.parse(data?.getOrganizations?.[0]?.scaffolding ?? "[]")

  const [breadcrumbs, childrenDirectories, here] = React.useMemo(() => {

    let breadcrumbs = []
    let childrenDirectories = []
    let here

    if (called && !loading && scaffolding) {
      breadcrumbs = getBreadcrumbs(bonPath, scaffolding)
      here = breadcrumbs[breadcrumbs.length - 1]

      childrenDirectories = here.children
        .map(childID => {
          return scaffolding.find(item => item.id === childID)
        })
        .filter(x => typeof x !== "undefined")
    }

    return [breadcrumbs, childrenDirectories, here]

  }, [scaffolding, bonPath])

  const prova = React.useCallback((fn) => {

    return async (...args) => {
      try {
        await fn(...args)
      } catch (err) {
        alert(err.message)
      }
    }

  })

  const createDirectory = React.useCallback(prova(async () => {

    await checkCapabilities()

    const { id } = here

    const name = window.prompt("Inserisci un nome per la cartella:", "Nuova Cartella")

    await mutateScaffolding({
      variables: {
        organizationID: selectedOrganization?.id,
        action: {
          type: "ADD",
          parentID: `${id}`,
          name
        }
      }
    })

    refetch()

  }), [checkCapabilities, bonPath, scaffolding, here, selectedOrganization])

  const moveDirectory = React.useCallback(prova(async (object, destination) => {

    await checkCapabilities()

    const { id } = object
    const parentID = destination.id

    await mutateScaffolding({
      variables: {
        organizationID: selectedOrganization?.id,
        action: {
          type: "MOVE",
          parentID,
          id,
        }
      }
    })

    refetch()

  }), [checkCapabilities, path, scaffolding, here, selectedOrganization])

  const renameDirectory = React.useCallback(prova(async (id, originalName) => {

    await checkCapabilities()

    const name = window.prompt("Choose new name", originalName)

    if (name) {

      await mutateScaffolding({
        variables: {
          organizationID: selectedOrganization?.id,
          action: {
            type: "EDIT",
            id,
            name: name || originalName
          }
        }
      })

      refetch()
    }

  }), [checkCapabilities, path, scaffolding, here, selectedOrganization])

  const client = useApolloClient();
  const removeDirectory = React.useCallback(prova(async (id) => {

    await checkCapabilities()

    const organizationID = selectedOrganization?.id

    const error = new Error("Puoi eliminare SOLO cartelle senza certificati.")

    // check if path has any children in the scaffolding
    const isNotEmpty = scaffolding.find(directory => directory.id === id)?.children > 0

    if (isNotEmpty) { throw error }

    const { data: { certificates } } = await client.query({
      query: GET_SCAFFOLDING_CONTENTS,
      variables: {
        path: id,
        organizationID
      },
      fetchPolicy: 'network-only',
    });

    if (certificates.length > 0) {
      throw error
    } else {

      await mutateScaffolding({
        variables: {
          organizationID,
          action: {
            type: "REMOVE",
            id
          }
        }
      })

      refetch()

    }

  }), [checkCapabilities, scaffolding, here, selectedOrganization])


  const memoValue = React.useMemo(() => {

    return {
      scaffolding,
      breadcrumbs,
      childrenDirectories,
      path: bonPath,
      pathID: here?.id,
      createDirectory,
      moveDirectory,
      renameDirectory,
      getBreadcrumbs,
      removeDirectory
    }

  }, [
    scaffolding,
    breadcrumbs,
    childrenDirectories,
    bonPath,
    createDirectory,
    moveDirectory,
    getBreadcrumbs,
    renameDirectory,
    removeDirectory
  ])

  return (
    <scaffoldingContext.Provider value={memoValue}>
      {loading ? null : props.children}
    </scaffoldingContext.Provider>
  )

}

export default ScaffoldingProvider