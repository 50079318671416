import React, { createContext, useMemo, useCallback } from "react";
import gql from 'graphql-tag'
import { useQuery } from "@apollo/react-hooks";
import throttle from "lodash.throttle";
import { useOrganizations } from "modules/organization-provider";

const QUOTA_QUERY = gql`
  query getQuota($organizationID: String) {
    getQuota(organizationID: $organizationID) {
      usedByMe
      usedByMyOrg
      personalQuota
      orgQuota
    }
  }
`

export const userQuotaContext = createContext();
export const { Provider, Consumer } = userQuotaContext;

export function useQuota() {
  const { quota, loading, refetch } = React.useContext(userQuotaContext)

  const throttledRefetch = useMemo(() => throttle(refetch, 1000), [refetch])

  const handleRefetch = React.useCallback(function handleRefetch() {
    if (typeof throttledRefetch === "function") {
      throttledRefetch()
    }
  }, [throttledRefetch])

  return [quota, { loading, refetch: handleRefetch }]
}

function UserQuotaProvider(props) {

  const { selectedOrganization } = useOrganizations()
  const { data, loading, networkStatus, called, refetch } = useQuery(QUOTA_QUERY, { variables: { organizationID: selectedOrganization.id } })

  const quota = useMemo(() => {
    if (typeof data === "undefined") return []

    const { usedByMyOrg, orgQuota } = data.getQuota
    const dataArray = []

    dataArray.push({
      id: 1,
      label: "Crediti Utilizzati",
      total: orgQuota,
      used: usedByMyOrg,
    })

    return dataArray
  }, [data])

  const value = useMemo(() => {

    return {
      loading: networkStatus === 4 || !called || loading,
      quota,
      refetch,
    }

  }, [networkStatus, called, loading, quota, refetch])

  return (
    <Provider
      value={value}
    >
      {props.children}
    </Provider>
  );
}

export default UserQuotaProvider