import React from 'react'

export const mastercard = ({ className }) => (
  <svg className={className} viewBox="0 0 48 48">
    <g>
      <rect
        x="1"
        y="7"
        width="46"
        height="34"
        rx="3"
        ry="3"
        fill="#243747"
      ></rect>{" "}
      <circle cx="19" cy="24" r="8" fill="#e61c24"></circle>{" "}
      <circle cx="28.934" cy="24" r="8" fill="#f99f1b"></circle>{" "}
      <path
        d="M23.967,17.736h0a7.985,7.985,0,0,0,0,12.528h0a7.985,7.985,0,0,0,0-12.528Z"
        fill="#f26622"
      ></path>
    </g>
  </svg>
);

export const visa = ({ className }) => (
  <svg className={className} viewBox="0 0 48 48">
    <g>
      <rect x="1" y="14" fill="#E6E6E6" width="46" height="19"></rect>{" "}
      <path
        fill="#E79800"
        d="M4,41h40c1.657,0,3-1.343,3-3v-5H1v5C1,39.657,2.343,41,4,41z"
      ></path>{" "}
      <path
        fill="#1A1876"
        d="M44,7H4c-1.657,0-3,1.343-3,3v5h46v-5C47,8.343,45.657,7,44,7z"
      ></path>{" "}
      <polygon
        fill="#1A1876"
        points="19.238,28.8 21.847,28.8 23.48,19.224 20.87,19.224 "
      ></polygon>{" "}
      <path
        fill="#1A1876"
        d="M28.743,23.069c-0.912-0.443-1.471-0.739-1.465-1.187c0-0.398,0.473-0.824,1.495-0.824 c0.836-0.013,1.51,0.157,2.188,0.477l0.354-2.076c-0.517-0.194-1.327-0.402-2.339-0.402c-2.579,0-4.396,1.299-4.411,3.16 c-0.015,1.376,1.297,2.144,2.287,2.602c1.016,0.469,1.358,0.769,1.353,1.188c-0.006,0.642-0.811,0.935-1.562,0.935 c-1.158,0-1.742-0.179-2.793-0.655l-0.366,2.144c0.61,0.267,1.737,0.499,2.908,0.511c2.744,0,4.525-1.284,4.545-3.272 C30.944,24.581,30.249,23.752,28.743,23.069z"
      ></path>{" "}
      <path
        fill="#1A1876"
        d="M38.007,19.233H35.99c-0.625,0-1.092,0.171-1.367,0.794l-3.876,8.776h2.741c0,0,0.448-1.18,0.55-1.439 c0.3,0,2.962,0.004,3.343,0.004c0.078,0.335,0.318,1.435,0.318,1.435h2.422L38.007,19.233z M34.789,25.406 c0.108-0.276,1.173-3.011,1.386-3.591c0.353,1.651,0.009,0.049,0.781,3.591H34.789z"
      ></path>{" "}
      <path
        fill="#1A1876"
        d="M17.049,19.231l-2.556,6.53l-0.272-1.327l-0.915-4.401c-0.158-0.606-0.616-0.787-1.183-0.808H7.913 L7.88,19.424c1.024,0.248,1.939,0.606,2.742,1.05l2.321,8.317l2.762-0.003l4.109-9.558H17.049z"
      ></path>
    </g>
  </svg>
);

export const amex = ({ className }) => (
  <svg
    className={className} viewBox="0 0 48 48"
  >
    <title>amex</title>
    <g>
      <path
        fill="#007AC6"
        d="M44,7H4c-1.657,0-3,1.343-3,3v28c0,1.657,1.343,3,3,3h40c1.657,0,3-1.343,3-3V10C47,8.343,45.657,7,44,7z"
      ></path>{" "}
      <path
        fill="#FFFFFF"
        d="M10.533,24.429h2.33l-1.165-2.857L10.533,24.429z M43,19h-5.969l-1.456,1.571L34.264,19H21.598l-1.165,2.571 L19.268,19h-5.096v1.143L13.59,19H9.222L5,29h5.096l0.582-1.571h1.456L12.716,29h5.678v-1.143L18.831,29h2.912l0.437-1.286V29 h11.648l1.456-1.571L36.594,29h5.969l-3.785-5L43,19z M25.383,27.571h-1.602V22l-2.475,5.571h-1.456L17.375,22v5.571h-3.349 L13.444,26H9.95l-0.582,1.571H7.475l3.057-7.143h2.475l2.766,6.714v-6.714h2.766l2.184,4.857l2.038-4.857h2.766v7.143H25.383z M39.797,27.571h-2.184l-1.893-2.429l-2.184,2.429h-6.552v-7.143h6.697l2.038,2.286l2.184-2.286h2.038L36.739,24L39.797,27.571z M28.586,21.857v1.286h3.64v1.429h-3.64V26h4.077l1.893-2.143l-1.747-2H28.586z"
      ></path>
    </g>
  </svg>
);

const cards = {
  amex,
  mastercard,
  visa
}

export default ({ brand, ...props }) => {

  const CardComponent = cards[brand]

  return <CardComponent {...props} />

}