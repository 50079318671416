import React from "react";
import { Container, Dimmer, Loader } from "semantic-ui-react"

function PageLoading() {
  return (
    <Container>
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
    </Container>
  );
}

export default PageLoading;
