import React from 'react'
import { Checkbox } from 'semantic-ui-react'

const Switch = ({ options, value, onChange }) => {
  const [innerValue, setValue] = React.useState(value)

  React.useEffect(() => {
    onChange(innerValue)
  }, [innerValue, onChange])

  return (
    <div
      className="
        flex 
        justify-center 
        items-center

        text-lg
      "
    >
      <div className={`cursor-pointer ${!innerValue && "font-bold"}`}
        onClick={() => setValue(0)}
      >
        {options[0]}
      </div>

      <Checkbox
        className="mx-4"
        toggle
        checked={innerValue}
        onClick={() => setValue(!innerValue)}
      />

      <div
        className="cursor-pointer relative"
        onClick={() => setValue(1)}
      >
        <span className={innerValue && "font-bold"}> {options[1]} </span>
        <div
          className="
            inline-block 
            ml-4 
            text-base 
            text-green-600 
            font-xs 
          "
        >
          {innerValue
            ? "Risparmi il 10%"
            : "Risparmia il 10%"}
        </div>
      </div>
    </div>

  )
}

export default Switch