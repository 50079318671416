import React from 'react'

import 'styled-components/macro'
import { Modal, Button } from 'semantic-ui-react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Icon from 'components/Icon'
import CardIcon from 'components/cc-icons'
import Switch from 'components/switch'
import PageLoading from 'components/PageLoading'
import ErrorMessage from 'components/error-message'


/**
 * A Single Product Card
 */
function ProductCard({
  name,
  credits,

  featured,
  isRecurring,

  disabled,
  loading,
  buying,
  success,

  error,

  onClick,
  onConfirm,

  year,
  month,
  oneshot,

  paymentMethod,
}) {

  const billingPeriod = isRecurring ? 12 : 1
  const plan = isRecurring ? billingPeriod === 12 ? year : month : oneshot

  const singleCreditPrice = Math.floor(plan.price / credits / billingPeriod * 100) / 100

  return (
    <div
      className={`
        border
        rounded-lg 
        shadow-lg 

        p-8 
        pb-2
        
        justify-center 
        items-end 

        ${featured && "bg-primary text-white"}
      `}
      css={`
        transform: scale(${featured ? 1.075 : .925})
      `}
    >
      <div className="text-center pt-0 rounded">
        <div className="uppercase font-bold text-sm mb-8">{name}</div>

        <h4 className="font-bold font-display text-6xl">{credits}</h4>
        <div className="font-bold">crediti</div>

        <div className="my-8">
          <div className="font-bold">
            {plan.price}€ {isRecurring && 'l\'anno'}
          </div>
          <div>
            <small>{singleCreditPrice}€ a credito</small>
          </div>
        </div>

        <div className="h-24 overflow-hidden">

          <div className="h-48" css={`
            transform: translateY(${buying ? "-50%" : "0%"});
            transition: all .3s ease-out;
          `}>

            <div className="h-24">
              <Button
                inverted={featured}
                primary={!featured}
                fluid
                onClick={onClick}
                disabled={disabled}
              >
                Acquista
              </Button>
            </div>

            <div className="h-24">
              <Button
                inverted={featured}
                primary={!featured}
                size="small"
                fluid
                onClick={() => onConfirm(plan.id)}
                disabled={disabled || loading || success}
                loading={loading}
              >
                {error && <Icon name="close" />}
                {success && <Icon name="checkmark" />}
                {!success && !error && "Conferma"}
              </Button>

              <div className="flex items-top my-4 justify-center text-left">
                <div className="mr-4">
                  <CardIcon brand={paymentMethod.card.brand} className="w-8" />
                </div>

                <div className="text-sm">
                  **** **** <strong>{paymentMethod.card.last4}</strong>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  )

}

export function BuyCredits(props) {
  const {
    optionalText,
    paymentMethod,
    products,
    currentPlan,
    onBuy,
    error,
  } = props

  const [buying, setBuying] = React.useState(null)
  const [loading, setLoading] = React.useState(null)
  const [done, setDone] = React.useState(null)
  const [failed, setFailed] = React.useState(null)

  // stores and sets the type of payment, 0 is one-shot, 1 is recurring
  const [isRecurring, setRecurring] = React.useState(null)

  const handleClick = React.useCallback(async (productID) => {
    setBuying(productID)
  }, [setBuying])

  const handleBuyClick = React.useCallback(async (pid, productID) => {
    setLoading(productID)
    try {
      await onBuy(pid)
      setDone(productID)
    } catch (err) {
      setFailed(productID)
    }

    setLoading(null)
  }, [setLoading, onBuy, setDone, setFailed])

  return (
    <div className="p-16 py-8">
      <div className="text-center mb-12">

        <h3 className="font-display text-3xl font-bold mb-4">Hai bisogno di più crediti</h3>
        <div>
          {optionalText ? optionalText : "Ottieni nuovi crediti per certificare i tuoi dati"}
        </div>

      </div>

      <div className="mb-16">
        <Switch
          options={[
            "Acquisto Singolo",
            `Aggiungi ad abbonamento ${currentPlan?.billingPeriod === 1 ? "mensile" : "annuale"}`
          ]}
          onChange={setRecurring}
          value={isRecurring}
        />
      </div>

      <div className="grid grid-cols-3 gap-8 mb-16">

        {products?.map((product, index) => <ProductCard
          key={product.id}
          onClick={() => handleClick(product.id)}
          onConfirm={(pid) => handleBuyClick(pid, product.id)}
          buying={buying === product.id}
          loading={loading && loading === product.id}
          disabled={loading && loading !== product.id}
          error={failed && failed === product.id}
          success={done && done === product.id}
          {...product}
          paymentMethod={paymentMethod}
          isRecurring={isRecurring}
          discount={isRecurring ? 0.9 : 1}
          featured={index === 1}
        />)}

      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <div className="flex items-center justify-center">
        <Icon nucleo="support" size="small" /> <span>Hai domande sui crediti? <a href="mailto:hello@timesafe">Scopri di più</a></span>
      </div>

    </div>
  )

}

/**
 * Wraps content component in a modal
 */
export function BuyCreditsModal({ isOpen, onClose, ...props }) {

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Content image>
        <Modal.Description>
          <BuyCredits {...props} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}


/**
 * Provider with state & loading logic
 */
export const BuyCreditsContext = React.createContext({ open: false })

export function useBuyCredits() {
  const { setOpen, setOptionalText, success, error } = React.useContext(BuyCreditsContext)

  const open = React.useCallback((text) => {
    if (typeof text === "string") {
      setOptionalText(text)
    }
    setOpen(true)
  }, [setOpen, setOptionalText])

  return [open, { success, error }]
}

function transformData(queryData) {
  return {
    currentPlan: queryData?.self?.defaultOrg?.plan?.billingPeriod,
    paymentMethod: JSON.parse(queryData?.self?.defaultOrg?.paymentMethod),
    products: queryData?.self?.defaultOrg?.products
  }
}

export function useDataLayer() {
  const [getData, getDataMeta] = useLazyQuery(GET_PAYMENT_METHOD)
  const [buyCredits] = useMutation(BUY_CREDITS)

  return { buyCredits, getData, getDataMeta }
}

export function BuyCreditsProvider({ onModalOpen, onBuySuccess, loading, ...props }) {

  const [optionalText, setOptionalText] = React.useState("")
  const [isOpen, setOpen] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const state = React.useMemo(() => {
    return {
      isOpen,
      setOpen,
      setOptionalText,
      success
    }
  }, [isOpen, setOpen, success, setOptionalText])

  const { buyCredits, getData, getDataMeta } = useDataLayer()

  const handleBuy = React.useCallback(async (pid) => {

    try {
      await buyCredits({
        variables: {
          pid: `${pid}`
        }
      })
      setSuccess(true)
      setOpen(false)
    } catch (err) {
      setError(err.message)
      throw new Error(err.message)
    }

  }, [buyCredits])

  React.useEffect(() => {
    if (isOpen) {
      getData()
    }
  }, [isOpen, getData])

  return (
    <BuyCreditsContext.Provider value={state}>
      {props.children}
      {isOpen ?
        (!getDataMeta.called || getDataMeta.loading) ?
          <PageLoading /> :
          <BuyCreditsModal
            optionalText={optionalText}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            {...props}
            onBuy={handleBuy}
            error={error}
            {...(getDataMeta.data ? transformData(getDataMeta.data) : {})}
          />
        :
        null
      }
    </BuyCreditsContext.Provider>
  )
}


/**
 * Composing Data & Provider
 */
const GET_PAYMENT_METHOD = gql`
  fragment plan on Plan {
    id
    price
  }

  query getBuyCreditsStuff {
    self {
      id
      defaultOrg {
        id
        paymentMethod
        plan {
          id
          billingPeriod
        }
        products {
          id
          name
          credits
          oneshot {
            ...plan
          }
          month {
            ...plan
          }
          year {
            ...plan
          }
        }
      }
    }
  }
`

const BUY_CREDITS = gql`
  mutation purchasePlan($pid: String) {
    purchasePlan(pid: $pid)
  }
`