const Cookies = require("js-cookie");

export function persistUserData(data) {
  persistUser({ ...getUser(), ...data })
}

export function getUser() {
  const storedUser =
    localStorage.getItem("trustime-user") ||
    Cookies.get("trustime-user") ||
    "{}";

  const user = JSON.parse(storedUser);

  return user;
}

export function isLogged() {
  const { token } = getUser();

  return token;
}

export function persistUser(user) {
  const string = JSON.stringify(user);

  localStorage.setItem("trustime-user", string);
  Cookies.set("trustime-user", string, {
    expires: 1,
    domain: process.env.REACT_APP_DOMAIN,
  });
}

export function setUser(merge) {
  const user = getUser()
  const newUser = { ...user, ...merge }

  persistUser(newUser)
}

export function logout() {
  localStorage.removeItem("trustime-user");
  Cookies.remove("trustime-user");
}
