import React from "react"
import "styled-components/macro"

function ContentWrapper(props) {
  const { children } = props

  return (
    <div
      className="
      max-w-6xl
      mx-auto
      h-full

      flex

      items-center
      justify-around

      flex-1
    "
    >
      <div
        className="p-8"
      >
        {children}
      </div>
    </div>
  )
}

function FullpageNotification(props) {
  const { icon, title, text } = props

  return (
    <ContentWrapper>
      {icon && (
        <div
          className="
            flex justify-center
            text-center
            [ animated bounce delay-quarters fast ] 
          "
          css={`
            > * {
              @media screen and (max-width: 768px) {
                font-size: 6rem !important;
              }
              @media screen and (max-width: 425px) {
                font-size: 4rem !important;
              }

            }
          `}
        >
          {icon}
        </div>
      )}

      {title && (
        <h3
          className="
            mt-10 sm:mt-12 lg:mt-16
            mb-4 lg:mb-8
            sm:mx-4
            sm:px-4
            
            text-2xl sm:text-3xl lg:text-4xl 
            
            font-black
            font-display
            text-center
          "
        >
          {title}
        </h3>
      )}

      {text && (
        <div
          className="
            text-center

            font-display

            text-md sm:text-xl
          "
        >
          {text}
        </div>
      )}
    </ContentWrapper>
  )
}

export default FullpageNotification