import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { getUser, setUser } from '@trustime/common/user-service'
import LoadingOverlay from 'components/LoadingOverlay'

const organizationContext = React.createContext()

const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    self {
      id 
      fullName
      organizations {
        id
        name
        vat
        isOwner
        isAdmin
        maxUsers
        plan {
          id
          billingPeriod
          price
        }
      }
    }
  }
`

function OrganizationProvider(props) {
  const { data, loading, error } = useQuery(GET_ORGANIZATIONS)
  const { selectedOrg } = getUser()

  const handleSelection = React.useCallback((id) => {
    setUser({
      selectedOrg: id
    })

    window.location.href = "/"
  }, [])

  const value = React.useMemo(() => {
    const organizations = data?.self?.organizations

    const selectedOrganization = organizations && organizations.find(organization => organization.id === selectedOrg)

    return {
      organizations,
      selectedOrganization: selectedOrganization || organizations?.[0],
      changeOrganization: handleSelection
    }
  }, [data, selectedOrg])

  return (<organizationContext.Provider value={value}>
    {loading ? <LoadingOverlay /> : props.children}
  </organizationContext.Provider>)
}

export function useOrganizations() {

  const { organizations, selectedOrganization, changeOrganization } = React.useContext(organizationContext)

  return { organizations, selectedOrganization, changeOrganization }

}

export default OrganizationProvider