import { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getUser } from "@trustime/common/user-service";
import { useCapabilities } from "./capabilities-provider";

const CHECK_PAYMENT_METHOD = gql`
  query checkPaymentMethod {
    hasValidPaymentMethod
  }
`;

function useRedirect() {
  const [loading, setLoading] = useState(true);
  const [lockToPayment, setLockToPayment] = useState(false);
  const [lockToSignUp, setLockToSignUp] = useState(false);

  const { isAdmin, isOwner } = useCapabilities();
  const user = getUser();
  const { data, loading: queryLoading } = useQuery(CHECK_PAYMENT_METHOD);

  const name = user?.name;

  useEffect(() => {
    if (data) {
      const hasValidPaymentMethod = data?.hasValidPaymentMethod;

      if ((isAdmin > 0 || isOwner > 0) && !hasValidPaymentMethod) {
        // Se admin o owner allora gli spetta la gestione del pagamento
        setLockToPayment(true)
      } else if (!name) {
        setLockToSignUp(true)
      }

      setLoading(false);
    }
  }, [data, name, isAdmin, isOwner]);

  return { loading: loading || queryLoading, lockToPayment, lockToSignUp };
}

export default useRedirect;
