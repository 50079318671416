import React, { useState, useCallback } from "react"
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { Icon } from "semantic-ui-react";

import 'react-multi-email/style.css';
import "styled-components/macro"

function EmailLabel(props) {
  const { index, email, removeEmail } = props

  return (
    <div
      className="
        p-2
        m-1
        ml-0
        bg-gray-300
        rounded
      "
    >
      {email}
      <span className="ml-2 cursor-pointer" onClick={() => removeEmail(index)}>
        <Icon name="close" size='small' />
      </span>
    </div>
  )
}

function EmailsInput(props) {
  const { handleChange } = props
  const [emails, setEmails] = useState([])
  
  const onChange = useCallback(
    function onChange(e) {
      setEmails(e)
      if (handleChange) {
        handleChange(e)
      }
    },
    [handleChange, setEmails]
  )

  return (
    <ReactMultiEmail
      placeholder="mario.rossi@org.com"
      emails={emails}
      onChange={onChange}
      validateEmail={email => isEmail(email)}
      getLabel={(email, index, removeEmail) => <EmailLabel key={index} email={email} index={index} removeEmail={removeEmail} />}
      css={`
        display: flex !important;
        align-items: center:
        width: 100% !important;
        padding: .5 !important;
        min-height: 3rem !important;
        border-radius: .25rem !important;
        border: 2px solid #e2e8f0 !important;
        
        :focus-within {
          border: 2px solid #3D79FF !important;
          outline: 0 !important;
        }
      `}
    />
  );
}

export default EmailsInput