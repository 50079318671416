import React from 'react';
import { Button } from 'semantic-ui-react'

function TTButton(props) {
  const { children } = props

  
  return (
    <Button {...props} >
      {children}
    </Button>
  )
}

TTButton.Group = Button.Group
TTButton.Content = Button.Content

export default TTButton