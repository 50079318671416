import React from "react";
import styled, { css } from "styled-components";

function SvgLogo2(props) {
  const {
    animated,
    color = "#FFF",
    background = ["#3D79FF", "#2557C6"],
  } = props;

  return (
    <svg width={78} height={78} {...props}>
      <defs>
        <linearGradient
          x1="105.351%"
          y1="5.968%"
          x2="8.747%"
          y2="78.156%"
          id="logo2_svg__b"
        >
          <stop stopColor={background[0]} offset="0%" />
          <stop stopColor={background[1]} offset="100%" />
        </linearGradient>
        <path
          d="M36.98 1.599l22 12.637A10 10 0 0164 22.907v25.186a10 10 0 01-5.02 8.671l-22 12.637a10 10 0 01-9.96 0l-22-12.637A10 10 0 010 48.093V22.907a10 10 0 015.02-8.671l22-12.637a10 10 0 019.96 0z"
          id="logo2_svg__a"
        />
      </defs>
      <BackGroup
        animated={animated}
        fill="none"
        fillRule="evenodd"
      >
        <mask id="logo2_svg__c" fill={color}>
          <use xlinkHref="#logo2_svg__a" />
        </mask>

        <use fill="url(#logo2_svg__b)" xlinkHref="#logo2_svg__a" />
      </BackGroup>
      <LogoGroup
        animated={animated}
        mask="url(#logo2_svg__c)"
        fill={color}
        fillRule="nonzero"
      >
        <path d="M19.547 35.621l3.494-2.159c.08 1.233.466 2.698 1.39 5.079l-2.648 1.895c-1.834 1.12-2.538 3.732-1.568 5.829.969 2.09 3.251 2.887 5.085 1.767l7.658-4.676c1.843-1.125 2.69-3.43 2.072-5.624-.353-.948-1.047-2.497-1.564-3.14-.892-1.106-.833-2.832.139-3.86.971-1.026 2.48-.968 3.372.138 1.293 1.597 2.305 4.305 2.498 4.832l.05.16c1.413 4.732-.414 9.911-4.338 12.307l-7.659 4.676c-4.166 2.543-9.34.745-11.54-4.006-2.2-4.751-.603-10.677 3.56-13.218z" />
        <path d="M45.453 36.379l-3.632 2.144c-.08-1.232-.483-2.486-1.407-4.867l2.803-2.092c1.834-1.12 2.538-3.732 1.568-5.829-.969-2.09-3.251-2.887-5.085-1.767l-7.658 4.676c-1.843 1.125-2.69 3.43-2.072 5.624.353.948 1.047 2.497 1.564 3.14.892 1.106.833 2.832-.139 3.86-.971 1.026-2.48.968-3.372-.138-1.293-1.597-2.305-4.305-2.498-4.832l-.05-.16c-1.413-4.732.414-9.911 4.338-12.307l7.659-4.676c4.166-2.543 9.34-.745 11.54 4.006 2.2 4.751.603 10.677-3.56 13.218z" />
      </LogoGroup>
    </svg>
  );
}

const BackGroup = styled.g`
  ${props =>
    props.animated &&
    css`
      animation-name: ckw;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      transform-origin: 50% 50%;
      display: inline-block;
      transition-timing-function: ease-in-out;

      @keyframes ckw {
        0% {
          transform: rotate(360deg) translate(7px, 4px);
        }
        100% {
          transform: rotate(0deg) translate(7px, 4px);
        }
      }
    `}
`;
const LogoGroup = styled.g`
  ${props =>
    props.animated &&
    css`
      animation-name: ckw2;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      transform-origin: 50% 50%;
      display: inline-block;
      transition-timing-function: ease-in-out;

      @keyframes ckw2 {
        0% {
          transform: rotate(0deg) translate(7px, 4px);
        }
        100% {
          transform: rotate(360deg) translate(7px, 4px);
        }
      }
    `}
`;

export default SvgLogo2;
