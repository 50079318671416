import "intersection-observer"

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "./styles/theme";
import { ApolloProvider } from "@apollo/react-hooks";

import { DndProvider } from 'react-dnd'
import DndBackend from "react-dnd-html5-backend";

import apolloClient from "utility/apollo-client";
import useRedirect from "utility/use-redirect"
import PrivateRoute from "components/private-route"
import LoadingOverlay from "components/LoadingOverlay";

import Logout from 'pages/logout'
import PersistUserData from "utility/persist-user-data-provider";
import { InviteUsersProvider } from "modules/invite-users";

import { BuyCreditsProvider } from 'modules/buy-credits'
import OrganizationProvider from "modules/organization-provider";
import ScaffoldingProvider from "modules/scaffolding-provider";
import GaProvider from "modules/ga-provider";

import UserQuotaProvider from "utility/user-quota-provider";
import CapabilitiesProvider from "utility/capabilities-provider";

const List = React.lazy(() => import("pages/list"));
const Single = React.lazy(() => import("pages/single"));
const Generic = React.lazy(() => import("pages/generic"));
const SignInPage = React.lazy(() => import("pages/sign-in"));
const ChoosePlanPage = React.lazy(() => import("pages/choose-plan"));
const SignUpPage = React.lazy(() => import("pages/sign-up"));
const ActivateUser = React.lazy(() => import("pages/activate-user"));
const ActivateByInvitation = React.lazy(() => import("pages/activate-by-invitation"));
const Payment = React.lazy(() => import("pages/payment"));
const CompleteSignUp = React.lazy(() => import("pages/complete-sign-up"));
const SettingsDeleteOrganization = React.lazy(() => import("pages/settings-delete-organization"));
const SettingsUser = React.lazy(() => import("pages/settings-user"));
const SettingsPassword = React.lazy(() => import("pages/settings-password"));
const SettingsReferral = React.lazy(() => import("pages/settings-referral"));
const SettingsDeleteUser = React.lazy(() => import("pages/settings-delete-user"));
const SettingsPlan = React.lazy(() => import("pages/settings-plan"));
const SettingsOrg = React.lazy(() => import("pages/settings-org"));
const RecoverPassword = React.lazy(() => import("pages/recover-password"));
const ResetPassword = React.lazy(() => import("pages/reset-password"));
const SettingsUsersManager = React.lazy(() => import("pages/settings-users-manager"));
const FindOutMore = React.lazy(() => import("pages/find-out-more"));
const Covid19 = React.lazy(() => import("pages/covid-19"));
const ChangeOrganizationPage = React.lazy(() => import("pages/change-organization"));

const GlobalStyles = createGlobalStyle`
  h1, h2, h3, h4 {
    color: inherit;
  }
`;

function SettingsRoutes() {
  return (
    <Routes>
      <Route path="/plan" exact element={<SettingsPlan />} />
      <Route path="/delete-organization" exact element={<SettingsDeleteOrganization />} />
      <Route path="/user" exact element={<SettingsUser />} />
      <Route path="/password" exact element={<SettingsPassword />} />
      <Route path="/delete-user" exact element={<SettingsDeleteUser />} />
      <Route path="/organization" exact element={<SettingsOrg />} />
      <Route path="/manage-users" exact element={<SettingsUsersManager />} />
      <Route path="/referral" exact element={<SettingsReferral />} />
    </Routes>
  );
}

function PrivateApp() {
  // redirect if user is not logged in
  const { loading, lockToPayment, lockToSignUp } = useRedirect()

  const inApp = !(lockToPayment || lockToSignUp)

  if (loading) return (<LoadingOverlay />)

  return (
    <Routes>
      {lockToPayment && (
        <>
          <Route path="*" element={<Navigate to="/payment" />} />
          <Route path="/payment" element={<Payment />} />
        </>
      )}
      {lockToSignUp && (
        <>
          <Route path="/" element={<Navigate to="/complete-sign-up" />} />
          <Route path="/complete-sign-up" element={<CompleteSignUp />} />
        </>
      )}
      {inApp && (
        <>
          <Route path="/" exact element={<Navigate to="/certificates/" />} />
          <Route path="/certificates/*" element={
            <ScaffoldingProvider>
              <Routes>
                <Route path="/" exact element={<List key="default" />} />
                <Route path="/*" element={<List key="sub" />} />
              </Routes>
            </ScaffoldingProvider>
          } />
          <Route path="/my-certificates/*" element={
            <ScaffoldingProvider>
              <Routes>
                <Route path="/" exact element={<List key="default" mine />} />
                <Route path="/*" element={<List key="sub" mine />} />
              </Routes>
            </ScaffoldingProvider>
          } />
          <Route path="/shared" exact element={<List key="shared" shared />} />
          <Route path="/certificate/:id" exact element={<Single />} />
          <Route path="/certificate/:id/:versione" exact element={<Single />} />
          <Route path="/settings/*" element={<SettingsRoutes />} />
          <Route path="/change-organization" element={<ChangeOrganizationPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/how-it-works" exact element={<FindOutMore />} />
          <Route path="/covid-19" exact element={<Covid19 />} />
        </>
      )}
    </Routes>
  );
}

function PrivateAppProviders() {
  return (
    <PersistUserData>
      <OrganizationProvider>
        <InviteUsersProvider>
          <BuyCreditsProvider>
            <UserQuotaProvider>
              <DndProvider backend={DndBackend}>
                <CapabilitiesProvider>
                  <PrivateApp />
                </CapabilitiesProvider>
              </DndProvider>
            </UserQuotaProvider>
          </BuyCreditsProvider>
        </InviteUsersProvider>
      </OrganizationProvider>
    </PersistUserData>
  )
}

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Router>
          <GaProvider>
            <GlobalStyles />
            <React.Suspense fallback={<LoadingOverlay />}>
              <Routes>

                <Route path="sign-in" element={<SignInPage />} />
                <Route path="sign-up/*" element={<SignUpPage />} />
                <Route path="choose-plan" exact strict element={<ChoosePlanPage />} />
                <Route path="sign-up/activate" exact strict element={<ActivateUser />} />

                <Route path="sign-up/activate-by-invitation" exact strict element={<ActivateByInvitation />} />

                <Route path="password/recover" element={<RecoverPassword />} />
                <Route path="password/reset" element={<ResetPassword />} />
                <Route path="password/edit" element={<Generic />} />

                <Route path="logout" element={<Logout />} />

                <PrivateRoute path="*" element={<PrivateAppProviders />} />

              </Routes>
            </React.Suspense>
          </GaProvider>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
