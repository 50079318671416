import React, { useState, useMemo, useCallback, useContext, useRef } from 'react'

import 'styled-components/macro'
import { Modal } from 'semantic-ui-react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Form, Formik } from 'formik';

import Icon from 'components/Icon'
import ErrorMessage from "components/error-message";
import Button from "components/Button";
import EmailsInput from 'components/emails-input'
import FullpageNotification from 'components/fullpage-notification'

export function InviteUsers(props) {
  const { handleSumbit, loading, error, success } = props


  const onSubmit = useCallback(async (values, actions) => {
    const { emails = [] } = values

    actions.setSubmitting(true);

    try {
      await handleSumbit(emails);
    } catch (err) {
      actions.setSubmitting(false);
    }
  }, [handleSumbit])

  return !success ? (
    <div className="p-16 py-8">

      <div className="text-center mb-16">
        <h3 className="font-display text-3xl font-bold mb-4">Invita collaboratori nella tua organizzazione</h3>
      </div>

      <div className="
        flex items-center justify-center
        max-w-3xl
        mb-8 mx-auto
        p-4
        bg-gray-100
        rounded-lg
      ">

        <div className="w-full">

          <Formik
            initialValues={{
              emails: [],
            }}
            onSubmit={onSubmit}
          >
            {formikProps => (
              <Form>
                <strong>Inserisci le mail di chi vuoi invitare:</strong>

                <div className="my-4">
                  <EmailsInput name="emails" type="email" handleChange={val => formikProps.setFieldValue("emails", val)} />
                </div>

                {error && <ErrorMessage>{error}</ErrorMessage>}

                <div>
                  <Button
                    primary
                    type="submit"
                    className="
                      w-full
                      my-6
                      py-2 
                      px-4 
                      h-12
                    "
                    loading={loading}
                  >
                    Invita
                  </Button>
                </div>

              </Form>
            )}
          </Formik>
        </div>

      </div>

      <div className="flex items-center justify-center mb-8">
        <Icon nucleo="support" size="small" /> <span>Hai domande sulla gestione utenti? <a href="mailto:hello@timesafe">Scopri di più</a></span>
      </div>

    </div>
  ) :
    <FullpageNotification
      icon={<Icon name="check circle" size='massive' color="text-primary" />}
      title="Invio completato!"
      text="I tuoi collaboratori riceveranno a breve una email contente il link di attivazione dei loro profili Timesafe."
    />
}

/**
 * Wraps content component in a modal
 */
export function InviteUsersModal(props) {
  const { isOpen, onClose, ...allTheRest } = props

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Modal.Content image>
        <Modal.Description>
          <InviteUsers {...allTheRest} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}


/**
 * Provider with state & loading logic
 */
export const InviteUsersContext = React.createContext({ isOpen: false })

export function useInviteUsers() {
  const { isOpen, setOpen } = useContext(InviteUsersContext)

  return [isOpen, setOpen]
}

export function InviteUsersProvider(props) {
  const { children } = props

  const [isOpen, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const [inviteUser, { loading }] = useMutation(INVITE_USER)
  const { data } = useQuery(GET_ORG)

  const name = data?.self?.defaultOrg?.name

  const sendEmails = useCallback(emails => {

    emails.forEach(async (email, index) => {
      try {
        await inviteUser({ variables: { email, orgName: name } })
        if (index === emails.length - 1) {
          setSuccess(true)
        }
      } catch (err) {
        setError(err.message)
      }
    })

  }, [inviteUser, name, setSuccess, setError])

  const _setOpen = useCallback(() => void setOpen(true), [setOpen])

  const state = useMemo(() => {
    return {
      isOpen,
      setOpen: _setOpen,
    }
  }, [isOpen, _setOpen])

  const resetForm = useCallback(
    function resetForm() {
      setOpen(false)
      setError(false)
      setSuccess(false)
    },
    [setOpen, setError, setSuccess]
  )

  return (
    <InviteUsersContext.Provider value={state}>
      {children}
      {isOpen ? (
        <InviteUsersModal
          isOpen={isOpen}
          handleSumbit={sendEmails}
          onClose={resetForm}
          loading={loading}
          error={error}
          success={success}
        />
      ) : null}
    </InviteUsersContext.Provider>
  )
}

const INVITE_USER = gql`
  mutation inviteUser($email: String!, $orgName: String!) {
    inviteUser(email: $email, orgName: $orgName)
  }
`
const GET_ORG = gql`
  query getSelf {
    self {
      defaultOrg {
        name
      }
    }
  }
`