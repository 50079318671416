import React, { createContext, useMemo } from "react";
import { useOrganizations } from "modules/organization-provider";
import { useQuota } from "./user-quota-provider";

export const capabilitiesContext = createContext();
export const { Provider, Consumer } = capabilitiesContext;

export function useCapabilities() {
  const capabilities = React.useContext(capabilitiesContext)
  return { ...capabilities }
}

function CapabilitiesProvider(props) {
  const { selectedOrganization } = useOrganizations()
  const [quota] = useQuota()
  
  const capabilities = useMemo(() => {
    const isOrganization = selectedOrganization?.maxUsers > 1
    const isAdmin = selectedOrganization?.isAdmin > 0
    const isOwner = selectedOrganization?.isOwner > 0
  
    const isPersonal = !isOrganization && isAdmin && isOwner
    const isOrganizationOwner = isOrganization && isOwner
    const isOrganizationAdmin = isOrganizationOwner && isAdmin
    
    const { total, used } = quota[0] || {}

    return {
      isOrganization,
      isAdmin,
      isOwner,
      isPersonal,
      isOrganizationOwner,
      isOrganizationAdmin,
      credits: {
        canBuy: isAdmin || isOwner,
        canUse: total - used > 0
      },
      scaffolding: {
        canMove: isAdmin || isOwner,
        canCrate: true,
        canDelete: isAdmin || isOwner,
      }
    }
  }, [selectedOrganization, quota])

  return (
    <Provider
      value={{...capabilities}}
    >
      {props.children}
    </Provider>
  );
}

export default CapabilitiesProvider