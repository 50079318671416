import React from 'react'
import { Navigate } from 'react-router-dom'
import { logout } from '@trustime/common/user-service'

export default function Logout() {

  React.useEffect(() => {
    logout()
  }, [])

  return (
    <Navigate to="/sign-in" />
  )

}