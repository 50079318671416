const { ApolloClient } = require("apollo-client");
const { createHttpLink } = require("apollo-link-http");
const { setContext } = require("apollo-link-context");
const { InMemoryCache } = require("apollo-cache-inmemory");

function createApolloClient(getUser) {
  if (typeof process.env.REACT_APP_GRAPHQL_URI === "undefined")
    throw new Error("Missing GRAPHQL URI in .env");

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  });

  // adds user token to graphql context
  const authLink = setContext((_, { headers }) => {
    const { token } = getUser();

    return {
      headers: {
        ...headers,
        token: `${token}`,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return client;
}

export default createApolloClient;
