import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { isLogged } from "@trustime/common/user-service";

function RedirectToHome() {
  window.location.href = "https://home.timesafe.io";
  return null;
}

export default ({ element, path, ...props }) => {
  const location = useLocation();

  return (
    <Route {...props} element={isLogged() ? element : <RedirectToHome />} />
  );
};
