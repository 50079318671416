import React, { useEffect, createContext, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from 'graphql-tag'

import { persistUserData } from '@trustime/common/user-service'

import LoadingOverlay from "components/LoadingOverlay";
import FullpageNotification from "components/fullpage-notification";
import Icon from "components/Icon";

const GET_SELF = gql`
  query getSelf {
    self {
      id
      name
      lastName
      fullName
      cf
      email
      defaultOrg {
        id
      }
    }
  }
`

export const persistUserDataContext = createContext();
export const { Provider, Consumer } = persistUserDataContext;

function PersistUserData(props) {
  const [loading, setLoading] = useState(true)
  const [userQuery, { data, error }] = useLazyQuery(GET_SELF)

  console.log({ error, loading })

  useEffect(() => {
    userQuery()
  }, [userQuery])

  useEffect(() => {
    if (data && data.self) {
      persistUserData(data.self)
      setLoading(false)
    }

  }, [data])

  if (error) return (
    <div
      className="h-full"
    >
      <FullpageNotification
        icon={<Icon name="warning circle" size='massive' color="text-orange-500" />}
        title="Si è verificato un errore"
        text={error.message}
      />
    </div>
  )
  if (loading) return (<LoadingOverlay />)

  return (
    <Provider
      value={{
        persistUserData
      }}
    >
      {props.children}
    </Provider>
  );
}

export default PersistUserData