import React from "react";
import "styled-components/macro";

import LogoBackground from "components/LogoBackground";

function LoadingOverlay() {
  return (
    <div
      css={`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff6;
      `}
    >
      <LogoBackground animated="true" />
    </div>
  );
}

export default LoadingOverlay;
