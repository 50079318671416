import React from "react";
import { Icon } from "semantic-ui-react";

function TTIcon(props) {
  const { className } = props;
  const { nucleo, ...rest } = props;

  const mergedClasses = `trustime trustime-${nucleo} ${className} `;

  return nucleo ? (
    <Icon {...props} className={mergedClasses} />
  ) : (
    <Icon {...rest} />
  );
}

TTIcon.defaultProps = {
  className: "",
};

export default TTIcon;
