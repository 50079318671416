import React from "react";

function ErrorMessage(props) {
  const { size } = props

  return (
    <div
      className={`
        my-4
        p-2
        px-4

        border-red-800
        bg-red-200
        text-red-800
        rounded

        text-sm

        ${size === "big" && `
          p-4
          text-base
        `}
        
      `}
    >
      {(typeof props.children === "string") ? props.children.replace('GraphQL error:', "") : props.children}
    </div>
  );
}

export default ErrorMessage;
